class OTF_Sticky_Layout_JS {
    top = 20;

    constructor() {
        this.setupValue();
        this.initSticky();
    }

    setupValue() {
        if ($('#main').outerHeight() < $('#secondary').outerHeight) {
            return false;
        }
        this.top = 20;
        let $adminBar = $('#wpadminbar');
        let $headerSticky = $('.osf-sticky-active');
        if ($adminBar.length > 0) {
            this.top += $adminBar.height();
        }
        if ($headerSticky.length > 0) {
            this.top += $headerSticky.outerHeight();
        }
    }

    initSticky() {

        let window_width = $(window).width();
        if (window_width < 992) {
            jQuery("#secondary > .inner, .woocommerce-single-style-3 .entry-summary .inner").trigger("sticky_kit:detach");
        } else {
            this._makeStickyKit();
        }

        $(window).resize(() => {
            window_width = $(window).width();
            if (window_width < 992) {
                jQuery("#secondary > .inner, .woocommerce-single-style-3 .entry-summary .inner").trigger("sticky_kit:detach");
            } else {
                this._makeStickyKit();
            }
        });
    }

    _makeStickyKit() {
        if ($('#main').outerHeight() < $('#secondary .inner').outerHeight()) {
            return false;
        }
        $("#secondary > .inner").stick_in_parent({
            parent: '#content > .wrap',
            offset_top: this.top
        });
        $(".woocommerce-single-style-3 .entry-summary .inner").stick_in_parent({
            parent: '.woocommerce-single-style-3 .site-main > .product',
            offset_top: this.top
        });
    }
}

$(document).ready(() => {
    new OTF_Sticky_Layout_JS();
});
